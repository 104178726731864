<template>
  <div
    class="transactionRecent__transactions listWithAmountNew"
    v-if="getRecentTransactions.length !== 0">
    <div class="header">
      <p class="header-1">
        {{ $t('dashboard_section_transactions') }}
      </p>
      <div class="go-to" @click="viewAll">
        {{ $t('dashboard_section_viewAll') }}
        <span class="icon-Arrow-small-right" />
      </div>
    </div>
    <ul>
      <template v-for="t in getRecentTransactions">
        <TransactionComponent @showDetails="showTransactionDetails = true" v-if="t" :transaction="t" :key="t.id" />
      </template>
    </ul>
    <Drawer class="default-drawer" :components="transactionDetails" :show.sync="showTransactionDetails" v-if="showTransactionDetails" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import TransactionComponent from '../components/Transaction';

import Drawer from '@/components/Drawer.vue';

export default {
  components: {
    TransactionComponent,
    Drawer
  },
  data() {
    return {
      showTransactionDetails: false,
      transactionDetails: [
        {
          name: 'transactionDetails',
          default: true,
          component: () => import('@m/transaction/views/TransactionDetails')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('transaction', ['getRecentTransactions'])
  },
  methods: {
    ...mapMutations('transaction', ['updateFilters']),
    viewAll() {
      this.updateFilters({
        contactId: null,
        cardId: null,
        dateRangeValue: null,
        startDate: null,
        endDate: null,
        minAmount: '0',
        maxAmount: '0',
        txnType: null,
        transferType: null,
        subType: null,
        cardIdFromFilter: null
      });
      window.analytics && window.analytics.track('homeViewAllTransactions');
      this.$router.push('/transaction/list');
    }
  }
};
</script>

<style lang="scss">
.transactionRecent {
  &__transactions {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 17px;

      .go-to {
        color: var(--branding);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;

        span {
          padding-left: 10px;
        }
      }
    }

    .recent {
      color: rgba(#3c3c43, 0.6);
      font-size: 13px;
      padding-bottom: 7px;
      text-transform: uppercase;
    }
    .no-transaction {
      color: rgba(0, 0, 0, 0.4);
      text-align: center;
      font-size: 16px;
      margin: 22px 0;
    }
  }
}
</style>
